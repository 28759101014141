import React from 'react'
import { graphql, Link } from 'gatsby'
import Image from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

export const query = graphql`
  query($taxonomy: String!) {
    allArtJson(filter: { art_taxonomy_slug: { eq: $taxonomy } }) {
      nodes {
        id
        name
        SKU
        variations {
          dimensions
          price
        }
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const Art = ({ data, pageContext }) => {
  return (
    <Layout>
      <SEO title={pageContext.name} />
      <main className="container lg:pt-12">
        <h1 className="text-xl lg:text-2xl mb-12 text-center">
          {pageContext.name}
        </h1>
        <Link
          aria-label="back to artwork page"
          className="block mb-4 text-sm "
          to="/art"
        >
          &larr; Back to artwork page
        </Link>
        <ul className="grid gap-4 md:gap-8 grid-cols-2 lg:grid-cols-3">
          {data.allArtJson.nodes.map(({ id, name, SKU, variations, image }) => (
            <li className="border border-gray-500 p-4 text-center" key={id}>
              <Image alt="" fluid={image.childImageSharp.fluid} />
              <h2 className="mt-4 text-base md:text-lg mb-2 font-normal">
                {name}
              </h2>
              <div>{SKU}</div>
              {variations.map(({ dimensions, price }) => (
                <div key={`${price}-${dimensions}`}>
                  <div>{dimensions}</div>
                  <div>${price}</div>
                </div>
              ))}
            </li>
          ))}
        </ul>
        <Link
          aria-label="back to artwork page"
          className="block mt-4 text-sm"
          to="/art"
        >
          &larr; Back to artwork page
        </Link>
      </main>
    </Layout>
  )
}

export default Art
